<template>
  <div class="login__details">
    <div class="">
      <form action="#" @submit.prevent="submit">
        <div class="form-group row">
          <label for="email" class="">Email</label>

          <div class="col-md-6">
            <input
              id="email"
              type="email"
              class="input"
              name="email"
              value
              required
              autofocus
              v-model="form.email"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="password" class="col-md-4 col-form-label text-md-right"
            >Password</label
          >

          <div class="col-md-6">
            <input
              id="password"
              type="password"
              class="input"
              name="password"
              required
              v-model="form.password"
            />
          </div>
        </div>

        <a href="#" @click="resetPassword">Glemt password?</a>

        <div class="buttons single">
          <button type="submit" class="button">Login</button>
        </div>
        <div v-if="form.resetSent">
          Du modtager snarest en email med et link til at ændre dit password.
        </div>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data: function() {
    return {
      form: {
        email: "",
        password: "",
        resetSent: false,
      },
      error: null,
      errorMessages: [
        {
          code: "auth/wrong-password",
          message: "Passwordet er ikke korrekt",
        },
        {
          code: "auth/user-not-found",
          message: "Brugeren findes ikke",
        },
      ],
    };
  },

  methods: {
    resetPassword(e) {
      e.preventDefault();
      const vm = this;
      console.log("request reset");
      var auth = firebase.auth();
      var emailAddress = this.form.email;

      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function() {
          // Email sent.
          vm.form.resetSent = true;
          console.log("email sent to " + emailAddress);
        })
        .catch(function(error) {
          // An error happened.
          console.log("email error", error);
        });
    },
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          this.$store.state.user = data.user;
          this.$store.state.loggedIn = true;
          this.$router.replace("/admin");
        })
        .catch((error) => {
          var errorCode = error.code;
          //var errorMessage = error.message;
          const customError = this.errorMessages.find(
            (e) => e.code === errorCode
          );
          this.error = customError ? customError.message : "Der skete en fejl";
          console.log(customError.message);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.login {
  &__details {
    width: 350px;
  }
  .alert {
    margin-bottom: 2rem;
    color: red;
    text-align: center;
  }
}
</style>
