<template>
  <div class="container login">
    <LoginDetails />
  </div>
</template>

<script>
import LoginDetails from "@/components/LoginDetails.vue";

export default {
  name: "Login",
  components: {
    LoginDetails,
  },
};
</script>
